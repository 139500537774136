<template>
  <div class="ravePartyBox" @scroll="pageScroll">
    <div class="raveBanner">
      <p class="bannerText" :class="bannerTextInfo"> </p>
      <p class="bannerMsg">
        {{ $t("raveParty.benefits") }}
      </p>
      <p class="bannerTime">
        {{ activeTime }} UTC+8
      </p>
    </div>
    <div class="raveIntorBox">
      <div class="raveIntorContent">
        <p>  
        {{ $t("raveParty.activityIntroduction1") }}
        </p>
        <p>{{ $t("raveParty.activityIntroduction2") }}</p>  
      </div>
    </div>
    <div class="partyTimeBox">
      <div class="numBigBox">
        <div class="numBox">
          <span>{{hours[0]}}</span>
          <span>{{hours[1]}}</span>
        </div>
        <div class="numBox">
          <span>{{minutes[0]}}</span>
          <span>{{minutes[1]}}</span>
        </div>
        <div class="numBox">
          <span>{{seconds[0]}}</span>
          <span>{{seconds[1]}}</span>
        </div>
      </div>
      <p class="curve"></p>
    </div>
    <div class="tabBox">
      <ul class="tabBtnBox">
        <li :class="tabFlag == 1 ? 'actExchange' : ''" @click="changeTabFlag(1)">{{ $t("raveParty.exchange") }}</li>
        <li :class="tabFlag == 2 ? 'actRank' : ''" @click="changeTabFlag(2)">{{ $t("raveParty.rank") }}</li>
        <li :class="tabFlag == 3 ? 'actDetails' : ''" @click="changeTabFlag(3)">{{ $t("raveParty.details") }}</li>
      </ul>
      <ul class="tabContentBox">
        <li v-show="tabFlag == 1" class="propGiftBox">
          <div class="remainingBox">
            <div class="remainingBody">
              <header>
                <p> {{ $t("raveParty.remainingPoints") }}</p>
                <div class="rechangeBox">
                  <span>{{ userScore }}</span>
                  <span @click="rechangeBtnClick">{{ $t("raveParty.recharge") }}</span>
                </div>
              </header>
              <div class="rechangeTitle">{{ $t("raveParty.pointsRewardTitle") }}</div>

              <ul class="rewardMsgBox">
                <li>{{ $t("raveParty.pointsReward") }}</li>
                <li>{{ $t("raveParty.pointsReward2") }}</li>
                <li>{{ $t("raveParty.pointsReward3") }}</li>
              </ul>
            </div>
          </div>
          <div class="remainingBox propBox">
            <ul class="remainingBody propBody">
                <li class="propsItem" v-for="(item,index) in giftList">
                  <div class="propsImg">
                    <img :src="item.icon" alt="">
                  </div>
                  <div class="propsName showEllipsis">{{ item.name }}</div>
                  <div class="propsPic"> <i></i> {{item.score * item.num}}</div>
                  <div class="selectNum">
                    <span @click="reduceGiftNum(index)">-</span>
                    <span>{{ item.num }}</span>
                    <span @click="addGiftNum(index)">+</span>
                  </div>
                  <div class="exchangeBtn" :class="item.num > 0 ? 'actExchangeBtn':''" @click="exchangeSend(item)">{{ $t("raveParty.exchange") }}</div>
                </li>
            </ul>
          </div>
        </li>
        <li v-show="tabFlag == 2" class="rankBox">
          <header class="rankTitleBox">
            <span :class="rankTabFlag == 1 ? 'actListTabBaLeft' : ''" @click="changeRankTab(1)">{{ $t("raveParty.rechargeList") }}</span>
            <span :class="rankTabFlag == 2 ? 'actListTabBaLeft' : ''" @click="changeRankTab(2)"> {{ $t("raveParty.consumptionList") }} </span>
          </header>
          <div class="rankBody">
            <p class="rankMsg"> {{ rankTabFlag == 1 ? $t("raveParty.rankingBased") :  $t("raveParty.duringTheEvent") }}</p>
            <ul class="rankListBox" v-if="rankListData.length">
              <li class="rankItem" v-for="(item,index) in rankListData">
                <div class="userInfo">
                  <span class="userRanking">{{ item.top | zeroFillTop}}</span>
                  <img @click="skipUserInfo(item)" v-if="item.headIcon" class="userProfile" :src="item.headIcon" alt="">
                  <img @click="skipUserInfo(item)" v-else class="userProfile" src="../../assets/raveParty/defaultAvatar.svg" alt="">

                  <span class="userName showEllipsis"> {{ item.nickname }} </span>
                </div>
                <div class="userPic">
                  {{ item.score }}
                </div>
              </li>
            </ul>
            <p v-if="!rankListData.length && !loadingFlag" class="noRankingBox">
              {{ $t("raveParty.noRanking") }}
            </p>
            <p class="loadingBox" v-if="loadingFlag">
              <img src="../../assets/raveParty/loading.png" alt="">
            </p>
          </div>
        </li>
        <li v-show="tabFlag == 3" class="rankBox detailsBox" :class="detailsTabFlag == 2 ? 'detailsBox1881' : ''">
          <header class="rankTitleBox">
            <span :class="detailsTabFlag == 1 ? 'actListTabBaLeft' : ''" @click="changeDetailsTab(1)">{{ $t("raveParty.rules") }}</span>
            <span :class="detailsTabFlag == 2 ? 'actListTabBaLeft' : ''" @click="changeDetailsTab(2)">{{ $t("raveParty.rewards") }}</span>
          </header>
          <div class="rankBody" v-show="detailsTabFlag == 1">
            <div class="detailBox">
              <p class="detailMsg ">{{ $t("raveParty.luckyGiftsRules") }}</p>
              <ul class="rulesList">
                <li class="rulesItem">
                  <header>{{ $t("raveParty.luckyGiftsRulesTitle") }}</header>
                  <p>{{ $t("raveParty.luckyGiftsRules1") }}</p>
                  <p>{{ $t("raveParty.luckyGiftsRules2") }}</p>
                  <p>{{ $t("raveParty.luckyGiftsRules3") }}</p>
                  <p>{{ $t("raveParty.luckyGiftsRules4") }}</p>
                  <p>{{ $t("raveParty.luckyGiftsRules5") }}</p>
                  <p>{{ $t("raveParty.luckyGiftsRules6") }}</p>
                  <p>{{ $t("raveParty.luckyGiftsRules7") }}</p>
                </li>
                <li class="rulesItem">
                  <header>{{ $t("raveParty.luckyGiftsRulesNote") }}</header>
                  <p>{{ $t("raveParty.luckyGiftsRulesNote1") }}</p>
                  <p>{{ $t("raveParty.luckyGiftsRulesNote2") }}</p>
                </li>
              </ul>
            </div>
            
          </div>
          <div class="rankBody" v-show="detailsTabFlag == 2">
            <div class="detailBox">
              <p class="detailMsg">{{ $t("raveParty.leaderboardReward ") }}</p>
              <p class="duringBox">
                {{ $t("raveParty.leaderboardReward1 ") }}
              </p>
              <ul class="tableBox">
                <i class="line"></i>
                <li>
                  <span>{{ $t("raveParty.rank ") }}</span>
                  <span>{{ $t("raveParty.privilege ") }}</span>
                </li>
                <li>
                  <span>1</span>
                  <span v-html="$t('raveParty.customizedLaunch15')"></span>
                </li>
                <li>
                  <span>2</span>
                  <span v-html="$t('raveParty.customizedLaunch7')"></span>
                </li>
                <li>
                  <span>3</span>
                  <span v-html=" $t('raveParty.customizedLaunch3')"></span>
                </li>
                <li>
                  <span style="padding-left: 0.2rem;" class="table4_6">4-6</span>
                  <span style="width: 300px;" v-html="$t('raveParty.customizedGift3')"></span>
                </li>
                <li>
                  <span>7-10</span>
                  <span v-html="$t('raveParty.customizedAvatarFrame3')"></span>
                </li>
              </ul>
              <p class="duringBox">
                {{ $t("raveParty.leaderboardReward2 ") }}
              </p>
              <ul class="tableBox tableBox2">
                <i class="line"></i>
                <li>
                  <span>{{ $t("raveParty.diamonds ") }}</span>
                  <span>{{ $t("raveParty.points ") }}</span>
                </li>
                <li>
                  <span>2,100</span>
                  <span>20</span>
                </li>
                <li>
                  <span>4,200</span>
                  <span>20</span>
                </li>
              </ul>
              <p class="duringBox clearOrigin">
                {{ $t("raveParty.consumedEvery ") }}
              </p>
              <ul class="tableBox tableBox2 tableBox3">
                <i class="line"></i>
                <li>
                  <span>{{ $t("raveParty.consumptionAmounts ") }}</span>
                  <span>{{ $t("raveParty.points ") }}</span>
                </li>
                <li>
                  <span>16,000 {{ $t("raveParty.diamonds ") }}</span>
                  <span>20</span>
                </li>
                <li>
                  <span>32,000 {{ $t("raveParty.diamonds ") }}</span>
                  <span>20</span>
                </li>
              </ul>
              <p class="duringBox">
                {{ $t("raveParty.leaderboardReward3 ") }}
              </p>
              <p class="duringBox">
                {{ $t("raveParty.leaderboardReward4 ") }}
              </p>
              <div class="iphoneBox">
                <p>{{ $t("raveParty.WhatsApp ") }} <span class="notAr">{{ $t("raveParty.WhatsAppNum ")}}</span></p>
                <p>{{ $t("raveParty.telegram ") }}<span class="notAr"> {{ $t("raveParty.telegramNum ") }}</span></p>
              </div>
            </div>
            
          </div>
        </li>
      </ul>
      <div class="myRankInfo" v-show="tabFlag == 2 && selfRankInfo.nickname">
        <div class="userInfo">
          <img v-if="selfRankInfo.headIcon" @click="skipUserInfo(selfRankInfo)" class="userProfile" :src="selfRankInfo.headIcon" alt="">
          <img v-else class="userProfile" @click="skipUserInfo(selfRankInfo)" src="../../assets/raveParty/defaultAvatar.svg" alt="">
          <p class="userName showEllipsis"> 
            <span>{{ selfRankInfo.nickname }}</span>  
            <span>{{ $t("raveParty.rank") }} {{ selfRankInfo.top | topFilter }}</span>  
          </p>
        </div>
        <div class="userPic">
          {{ selfRankInfo.score == 0 ? '--' : selfRankInfo.score }}
        </div>
      </div>
    </div>
    <!-- 兑换弹窗 -->
    <div class="popBox" v-if="popFlag > 0">
      <!-- 成功 -->
      <div class="popSuccessBox" v-if="popFlag == 1">
        <header>{{ $t("raveParty.redeemSuccessful ") }}</header>
        <div class="exchangeGiftBox">
          <img :src="successImg" alt="">
          <span>X {{ successInfo.cout }}</span>
        </div>
        <p class="exchangeSpend">{{ $t("raveParty.totalConsumption ") }} {{ successInfo.subScore }} {{ $t("raveParty.points1 ") }}</p>
        <div class="exchangeAfterBtn" @click="closePop">{{ $t("raveParty.ok ") }}</div>
      </div>
       <!-- 失败  -->
       <div class="popSuccessBox" v-if="popFlag == 2">
        <header>{{ $t("raveParty.redeemFailed ") }}</header>
        <p class="exchangeSpend pointNot">{{ tryAgain }}</p>
        <div class="exchangeAfterBtn" @click="closePop">{{ $t("raveParty.ok") }}</div>
      </div>
    </div>
  </div>
 
</template>

<script>
import { Toast } from "vant";
export default {
  name: "raveParty",
  data() {
    return {
      tabFlag: 1,
      rankTabFlag: 1,
      detailsTabFlag:2,
      popFlag: 0,
      // 时间
      hours:[0,0],
      minutes:[0,0],
      seconds:[0,0], 
      timer:null,
      bannerTextInfo:'',
      activeTitle:'Carnaval de primavera',
      // 礼物数据
      giftList:[],
      // 榜单数据
      rankListData:[],
      userScore:0,
      // 兑换失败提示语
      tryAgain:'',
      page: 0,
      size: 10,
      total:0,
      // 1本论 2上一轮
      round: 1,
      // type 1日 2周 3月 4 不限时间
      activeType: 4,
      // 避免重复请求
      notFast: true,
      // 自己的榜单信息
      selfRankInfo:{},
      // 兑换成功后礼物图片
      successImg:'',
      successInfo:{},
      loadingFlag: true,
      // 避免重复请求
      sendFlag: true,
      // 活动时间
      activeTime:''

    };
  },
  filters:{
    topFilter(top){
      if(top > 99){
        return '99+';
      }else if(top == 0){
        return '--'
      }else{
        return top
      }
    },
    zeroFillTop(top){
      if(top < 10){
        return '0'+top;
      }else{
        return top;
      }
    }
  },
  created() {
    //调用原生方法
    this.$completes.complete("getUserInfo");
    //原生调用h5方法
    window.getUserInfo = this.getUserInfo;
    window["getUserInfo"] = (data) => {
      this.getUserInfo(data);
    };
    document.title = this.$t('raveParty.title')
    document.body.style.backgroundColor = '#17110D';
    // 判断平台
    this.judgeClient()

    // this.getUserInfo() 

    

  },
  methods: { 

    // 获取礼物列表
    getGiftList(){
      let that = this
      let paramObjs = {
        option: 'get',
        host: this.$serviceIp + '/api/activity/carnival/gifts?token=' + that.token + '&uid=' + that.uid,
        data: '',
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(paramObjs).then((data) => {
        if (data.code == 200) {
          data.data.gifts.forEach(element => {
            element.num = 1
          });
          this.giftList = data.data.gifts;
          this.userScore = data.data.score;
        }
        else {
          Toast(data.message || this.$t('failed'))
        }
      })
    },
    // 跳转充值
    rechangeBtnClick(){
      // 跳转app端充值
      if (this.client == 'iOS') {
        window["webkit"].messageHandlers['h5JumpRecharge'].postMessage(JSON.stringify(""));
      } else if (this.client == 'Android') {
        nativeJs["h5JumpRecharge"]();
      }
    },
    // 增加兑换礼物数量
    addGiftNum(index){
      this.giftList[index].num++;
    },
    // 减少礼物兑换数量
    reduceGiftNum(index){
      if(this.giftList[index].num == 1) return;
      this.giftList[index].num--;
    },
    // 转换日期展示
    getMonthDay(t){
      console.log(t)
      const year = t.getFullYear(); // 获取当前年份，例如：2021
      const month = t.getMonth() + 1; // 获取当前月份，注意需要加1，例如：9
      const day = t.getDate(); // 获取当前日期，例如：22
      return month + '/' + day;
    },
    // 根据时间戳转换倒计时
    countdown(endTime) {
      this.timer = setInterval( ()=> {
        const now = new Date().getTime();
        const distance = endTime - now;
        
        if (distance < 0) {
          clearInterval(this.timer);
          return;
        }
        
        const days = this.zeroFill(Math.floor(distance / (1000 * 60 * 60 * 24)));
        const hours = this.zeroFill(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        const minutes = this.zeroFill(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
        const seconds = this.zeroFill(Math.floor((distance % (1000 * 60)) / 1000));

        this.days = days;
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
      }, 1000);
    },
    zeroFill(num){
      if(num < 10){
        return [0,num]
      }else{
        return [num.toString().slice(0,1),num.toString().slice(1)]
      }
    },
    // 关闭弹窗
    closePop(){
      // 恢复页面滚动
      this.canScroll();
      this.popFlag = 0;
    },
    // 点击兑换按钮
    exchangeSend(item){
      if(item.num == 0) return;
      this.successImg = item.icon;
      // 禁止页面滚动
      this.stopScroll();
      // 获取活动时间
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIp +
          "/api/activity/carnival/exchange?token=" +
          this.token +
          "&uid=" +
          this.uid+
          "&cout=" +
          item.num+
          "&id=" +
          item.id,
        data: "",
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(personLeveldatas).then((data) => {
        if (data.code == 200) {
          if(data.data.state == 0){
            this.popFlag = 2;
            this.tryAgain = data.data.failMsg;
          } else {
            this.successInfo = data.data;
            this.popFlag = 1;
          }
          this.userScore = data.data.score;
        }else {
          Toast(data.message || this.$t('failed'))
        }
      });

    },
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      this.headers = [
        'Content-Type:' + 'application/json; charset=utf-8',
        'deviceId:' + JSON.parse(data).deviceId,
        'lang:' + JSON.parse(data).lang,
        'version:' + JSON.parse(data).version,
        'os:' + JSON.parse(data).os,
        'model:' + JSON.parse(data).model,
        'fid:' + JSON.parse(data).fid,
        'uid:' + JSON.parse(data).userId
      ]
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      let langs = this.userInfo.lang.substring(0,2)
      let lang = this.userInfo.lang
      if (langs == "zh") {
          if (lang == "zh_TW" || lang == "zh_HK") {
          this.$i18n.locale = "tc";
          } else {
          this.$i18n.locale = "zh";
          }
      } else if (langs == "en") {
          this.$i18n.locale = "en";
          this.bannerTextInfo = 'bannerTextEn';
      } else if (langs == "ar") {
          this.$i18n.locale = "ar";
          this.bannerTextInfo = 'bannerTextTrAr';
      }else if (langs == "tr") {
          this.$i18n.locale = "tr";
          this.bannerTextInfo = 'bannerTextTrAr';
      } else if (langs == "es") {
          this.$i18n.locale = "es";
      } else if (langs == "pt") {
          this.$i18n.locale = "pt";
      } else {
          this.$i18n.locale = "en";
      }
      document.querySelector("html").setAttribute("lang", this.$i18n.locale);
      // 获取活动时间
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIp +
          "/api/activity/carnival/cfgA?token=" +
          this.token +
          "&uid=" +
          this.uid,
        data: "",
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(personLeveldatas).then((data) => {
        if (data.code == 200) {
          if (this.client == 'iOS') {
            this.countdown(new Date(data.data.stopT.replace(/-/g,'/')).valueOf())

            this.activeTime = this.getMonthDay(new Date(data.data.startT.replace(/-/g,'/'))) + '-' + this.getMonthDay(new Date(data.data.stopT.replace(/-/g,'/')));
          } else if (this.client == 'Android') {
            this.countdown(new Date(data.data.stopT).valueOf())
          }
        }else {
          Toast(data.message || this.$t('failed'))
        }
      });
      this.getGiftList()
    },
    // 判断平台
    judgeClient() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
        this.client = 'iOS';
      } else if (/(Android)/i.test(navigator.userAgent)) { //判断Android
        this.client = 'Android';
      } else {
        this.client = 'PC';
      }
      return this.client;
    },
    // 跳转资料页  需要uid
    skipUserInfo(item) {
      if (this.client == 'iOS') {
        window["webkit"].messageHandlers['skipUserInfo'].postMessage(JSON.stringify(item.userId));
      } else if (this.client == 'Android') {
        nativeJs["skipUserInfo"](JSON.stringify(item.userId));
      }
    },
    //分页
    pageScroll(e) {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight) {
          if(this.notFast && this.tabFlag == 2){
              this.page++;
              this.getRankList();
          }
      }
    },
    // 切换tab展示
    changeTabFlag(num){
      this.tabFlag = num;
      if(num == 2){
        this.rankListData = [];
        this.getRankList();
      }
    },
    // 获取自己当前排名信息
    getSelfRankInfo(){
      let rankInfo = {
        rankType: this.rankTabFlag == 1? 11: 12,
        round: this.round,
        type: this.activeType
      };
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIp +
          "/api/activity/carnival/role/rank?token=" +
          this.token +
          "&uid=" +
          this.uid+
          "&rankType=" +
          rankInfo.rankType+
          "&round=" +
          rankInfo.round+
          "&type=" +
          rankInfo.type,
        data: "",
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(personLeveldatas).then((data) => {
        if (data.code == 200) {
         this.selfRankInfo = data.data;
        }else {
          Toast(data.message || this.$t('failed'))
        }
      });
    },
    // 获取榜单
    getRankList(){
      this.loadingFlag = true;
      // 获取自己当前排名
      this.getSelfRankInfo()
      this.notFast = false;
      // rankType 11 12 本活动  13 14是活动二
      let rankInfo = {
        rankType: this.rankTabFlag == 1? 11: 12,
        round: this.round,
        type: this.activeType
      };
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIp +
          "/api/activity/carnival/role/page?token=" +
          this.token +
          "&uid=" +
          this.uid+
          "&rankType=" +
          rankInfo.rankType+
          "&round=" +
          rankInfo.round+
          "&type=" +
          rankInfo.type+
          "&page=" +
          this.page+
          "&size=" +
          this.size,
        data: "",
        header: JSON.stringify(this.headers),
      };
      if(this.sendFlag){
        this.sendFlag = false;
        this.$server.curlExec(personLeveldatas).then((data) => {
          if (data.code == 200) {
            this.loadingFlag = false;
            this.rankListData.push(...data.data.list);
            //  是否还有下一页
            if(data.data.list.length == data.data.size){
              this.notFast = true
            }
          }else {
            Toast(data.message || this.$t('failed'))
          }
          this.sendFlag = true;
        });
      }
      
    },
    // 切换榜单tab
    changeRankTab(num){
      this.rankTabFlag = num;
      this.rankListData = [];
      this.getRankList()
    },
    // 切换详情tab
    changeDetailsTab(num){
      this.detailsTabFlag = num;
    },
  },
};
</script>

<style scoped>
@import './raveParty.css'
</style>
